/**
 * Helps with the popup snackbars
 */
import React from 'react';
import { Snackbar} from "@mui/material";
import MuiAlert from '@mui/material/Alert';

// To remove automatic styling like the x button and tick from popup
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} icon={false} />;
});
function Popup({open, onClose, message }) {
    return (
        <Snackbar
            open={open}
            onClose={onClose}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Positioning at the top-center
    >
            <Alert onClose={onClose} severity="success" sx={{
                width: 'auto',
                minWidth: '150px',
                padding: '8px 16px',
                borderRadius: '20px',
                fontSize: '16px',
                display: 'inline-flex',
                backgroundColor: 'tomato'
            }}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default Popup;