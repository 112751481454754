import React from 'react';
import { Box, TextField, MenuItem, Button, Modal, Typography, FormControlLabel, Checkbox } from '@mui/material';

const cities = ["Auckland","Adelaide", "Brisbane", "Christchurch", "Dunedin", "Hamilton",
     "Hobart", "Melbourne", "Perth", "Rotorua", "Sydney", "Tauranga", "Wellington"];
const regions = ["North Island", "South Island", "Auckland Region", "Wellington Region", "Waikato", "Bay of Plenty", "Otago", "New South Wales",
    "Victoria", "Queensland", "South Australia", "Western Australia", "Tasmania"];
const ageRanges = [
    { label: "18-25", value:"18-25" },
    { label: "26-35", value: "26-35" },
    { label: "36-45", value: "36-45" },
    { label: "46+", value: "46+"},
];

// To help with filtering profiles on Link page
function Filter({ filters, setFilters, open, onClose, onApply }) {
    const handleChange = (event) => {
        const { name, value, checked, type } = event.target;

        // For Checkbox
        if (type === 'checkbox') {
            setFilters(prevFilters => ({
                ...prevFilters,
                [name]: checked,
            }));
        } else {

            // Reset city if region is selected and vice versa
            if (name === 'city') {
                setFilters(prevFilters => ({
                    ...prevFilters,
                    city: value,
                    region: '', //Clear region when city is selected
                }));
            } else if (name === 'region') {
                setFilters(prevFilters => ({
                    ...prevFilters,
                    region: value,
                    city: '', // Clear city when region is selected
                }));
            } else {
                setFilters(prevFilters => ({
                    ...prevFilters,
                    [name]: value,
                }));
            }
        }
    };

    // To clear filter
    const handleClear = () => {
        setFilters({ city: '', region: '', ageRange: '', onlyMaybes: false, heightPreference: false });
    }

    // Styling CSS for the filter
    const modalStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 4,
        marginTop: '30%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: 'white',
        transform: 'translateY(-50%)',
        borderRadius: '10px',
        '@media (max-width: 768px)': {
            marginTop: '60%', // This applies to screens below 768px width
        }
    };

    return (
        <Modal open={open} onClose={onClose} align="center">
            <Box sx={modalStyles}>
                <Typography variant="h6" gutterBottom>
                    Filter To Your Vibe
                </Typography>
                 <FormControlLabel
                    control={<Checkbox checked={filters.onlyMaybes || false} onChange={handleChange} name="onlyMaybes" />}
                    label="Show Only Maybes"
                />
                <FormControlLabel
                    control={<Checkbox checked={filters.heightPreference || false} onChange={handleChange} name="heightPreference" />}
                    label="Strict Height Preference"
                />
                <TextField select label="City" name="city" value={filters.city}
                onChange={handleChange} variant="outlined" sx={{ minWidth: 120 }}
                disabled={!!filters.region}>
                    {cities.map((city) => (
                        <MenuItem key={city} value={city}>
                            {city}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField select label="Region" name="region" value={filters.region}
                onChange={handleChange} variant="outlined" sx={{ minWidth: 120 }}
                disabled={!!filters.city}>
                    {regions.map((region) => (
                        <MenuItem key={region} value={region}>
                            {region}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField select label="Age Range" name="ageRange" value={filters.ageRange}
            onChange={handleChange} variant="outlined" sx={{ minWidth: 120 }}>
                {ageRanges.map((range) => (
                    <MenuItem key={range.value} value={range.value}>
                        {range.label}
                    </MenuItem>
                ))}
                </TextField>
                <Box sx={{ mt: 2 }}>
                    <Button variant="contained" sx={{color: "brown"}} onClick={onApply}>
                        Apply
                    </Button>
                    <Button variant="outlined" onClick={handleClear}>
                        Clear
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default Filter;