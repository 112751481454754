import React from 'react';
import UpcomingSpeedDates from '../components/UpcomingSpeedDates';

function SpeedDate() {
    return (
        <div>
            <h2 align="center">Speed Dates</h2>
            {/* Render the UpcomingSpeedDates component */}
            <UpcomingSpeedDates />
        </div>
    );
}

export default SpeedDate;
