import React, { useState } from 'react';
import { Box, Typography, Button} from '@mui/material';
import { getAuth } from 'firebase/auth'; // Import Firebase Authentication
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // Import Firebase Storage
import { getFirestore, doc, updateDoc } from 'firebase/firestore'; // Import Firestore
import { useNavigate } from "react-router-dom";
import { auth, db } from '../firebase'; // Import initialized Firebase services

function ProfilePictureUpload() {
    // State to hold the selected image file
    const [image, setImage] = useState(null);
    // State to hold the image preview URL
    const [preview, setPreview] = useState(null);
    // State to manage the upload status
    const [uploading, setUploading] = useState(false);

    // Initialize useNavigate
    const navigate = useNavigate();
    // Handle image file selection and set preview
    const handleImageChange = (e) => {
        const file = e.target.files[0]; // Get the first selected file
        if (file) {
            setImage(file); // Set the image state
            setPreview(URL.createObjectURL(file)); // Set the preview state with file URL
        }
    };

    // Handle image upload
    const handleUpload = async () => {
        const user = auth.currentUser; // Get the current user
        if (user && image) {
            setUploading(true); // Set uploading state to true
            const storage = getStorage(); // Get Firebase Storage
            const storageRef = ref(storage, `profilePictures/${user.uid}`); // Create a reference for the image in storage
            const uploadTask = uploadBytesResumable(storageRef, image); // Create an upload task

            // Monitor upload progress and handle completion or errors
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Handle upload progress if needed (optional)
                },
                (error) => {
                    console.error('ProfilePictureUpload: Error uploading image', error);
                    setUploading(false); // Set uploading state to false on error
                },
                async () => {
                    // Handle successful upload
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref); // Get the download URL of the uploaded image
                    const userRef = doc(db, 'users', user.uid); // Reference to the user's document in Firestore
                    await updateDoc(userRef, { profilePicture: downloadURL }); // Update the user's profile with the imageURL
                    console.log('ProfilePictureUpload: Profile picture updated successfully');
                    setUploading(false); // Set uploading state to false
                    // Redirect to profile or another page (optional)
                    navigate('/profile');
                }
            );
        }
    };

    return (
        <Box sx={{ p: 4, maxWidth: 600, mx: 'auto', textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
                Upload Your Profile Picture
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                Please upload a square image (1:1 or 3:4 aspect ratio).
            </Typography>
            <input type="file" accept="image/*" onChange={handleImageChange} /> {/* File input for image selection */}
            {preview && (
                <Box sx={{ mt: 2 }}>
                    <img src={preview} alt="Profile Preview" style={{ width: '100%', maxWidth: '400px', borderRadius: '50%' }} /> {/* Display image preview */}
                </Box>
            )}
            <Button variant="contained"
                    onClick={handleUpload}
                    disabled={uploading || !image}
                    sx={{ mt: 2, backgroundColor: 'brown' }}>
                {uploading ? ' Uploading...': 'Upload'} {/* Button text changes based on upload state */}
            </Button>
        </Box>
    );
}

export default ProfilePictureUpload;