import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';

// SpeedDateModal component for proposing and accepting speedDate requests
function SpeedDateModal({ open, onClose, onSubmit, profile }) {
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');

    const handleSubmit = () => {
        onSubmit({ date, time });
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <center><Box sx={{ p: 4, backgroundColor: 'white', borderRadius: 2,
            maxWidth: 400, marginLeft: '2%', marginRight: '2%', mt: 8}}>
                <Typography variant="h6" gutterBottom>
                    Propose a Speed Date with {profile.name}
                </Typography>
                <TextField
                    label="Select Date"
                    type="date"
                    fullWidth
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    sx={{ mb: 2 }}
                    InputLabelProps={{ shrink: true }}
                    />
                <TextField
                    label="Select Time"
                    type="time"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Box></center>
        </Modal>
    );
}

export default SpeedDateModal;