/**
 * Component to display the list of upcoming speed dates with a button to start the speed date
 */
/**TODO
 * Check if the time is being saved in firestore for the speedDate as its not showing on the speeDateRequest
 * Finish adding code to show the speed Date request current User sent
 */
import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Button, Avatar } from "@mui/material";
import { getAuth } from "firebase/auth";
import { collection, query, where, onSnapshot, getDoc, doc, addDoc } from "firebase/firestore";
import { db } from '../firebase';
import { useNavigate } from "react-router-dom";

const DAILY_API_URL = 'https://blackdate-9f53e19d72a9.herokuapp.com'; // Your server endpoint URL

// Component to display upcoming speed dates
function UpcomingSpeedDates() {
    const [upcomingDates, setUpcomingDates] = useState([]);
    const [userSentUpcomingDates, setUserSentUpcomingDates] = useState([]);
    const auth = getAuth();
    const currentUser = auth.currentUser.uid;
    const navigate = useNavigate();

    useEffect(() => {
        // Reference to the speed date requests collection in Firestore
        const speedDateRequestsRef = collection(db, 'speedDateRequests');
        // Query to get accepted speed date requests for the currentUser
        const q = query(speedDateRequestsRef, where('receiver', '==', currentUser), where('status', '==', 'accepted'));
        // Query Accepted Speed Date request current user has sent
        const qAccepted = query(speedDateRequestsRef, where('sender', '==', currentUser),
            where('status', '==', 'accepted'));
        // Real Time Listener
        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const datesData = await Promise.all(querySnapshot.docs.map(async (dateDoc) => {
                const requestData = dateDoc.data();
                const senderRef = doc(db, 'users', requestData.sender);
                const senderSnap = await getDoc(senderRef);
                const senderData = senderSnap.data();

                return {
                    id: dateDoc.id,
                    ...requestData,
                    senderUsername: senderData.profile.username,
                    senderProfilePicture: senderData.profilePicture
                };
            }));

            // Filter out completed speed dates
            const activeDates = datesData.filter(date => !date.completed);
            setUpcomingDates(activeDates);
        });

        // Set real Time listener for the pending requests sent by user to show current user in their notifications
        const unsubscribeSent = onSnapshot(qAccepted, async(querySnapshotP) => {
            // Map query results to an array of pendingRequests sent by user objects
            const sentRequestsData = await Promise.all(querySnapshotP.docs.map(async (sentRequestDoc) => {
                const sentRequestData = sentRequestDoc.data();
                const receiverRef = doc(db, 'users', sentRequestData.receiver);
                const receiverSnap = await getDoc(receiverRef);
                const receiverData = receiverSnap.data();
                return {
                    id: sentRequestDoc,
                    ...sentRequestData,
                    receiverUsername: receiverData.profile.username,
                    receiverProfilePicture: receiverData.profilePicture
                };
            }));

            // Filter here again as well
            const userSentActiveDates = sentRequestsData.filter(date => !date.completed);
            setUserSentUpcomingDates(userSentActiveDates);
        });

        return () => {
            unsubscribe();
            unsubscribeSent();
        }
    }, [currentUser]);

    // Function to handle starting the speed date
    const handleStartSpeedDate = async (date) => {
        //console.log('UpcomingSpeedDate: RoomURL = ', roomUrl);

        // Fetch current user's profile data
        const currentUserRef = doc(db, 'users', currentUser);
        const currentUserSnap = await getDoc(currentUserRef);
        const currentUserData = currentUserSnap.data();

        try {
            // Generate a token for the room by making a request to the server
            const response = await fetch(`${DAILY_API_URL}/generate-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userName: currentUserData.profile.username }),
            });

            // Check if response is OK
            /*if (!response.ok) {
                console.error(`UpcomingSpeedDate: Failed to generate token : ${response.statusText}`);
            } else {
                console.log(`UpcomingSpeedDate: Successfully generated token: ${response.statusText}`);
            }*/

            // Get the token from the response
            const { token, roomUrl } = await response.json();

            //console.log('UpcomingSpeedDates: RoomUrl = ', roomUrl);

            // Ensure the token is defined
            if (!token) {
                console.error('UpcomingSpeedDate: Token is undefined');
                 alert('Failed to get token. Please try again.');
                return;
            }

            // Notify the other user about the speed date
            const notificationRef = collection(db, 'notifications');
            await addDoc(notificationRef, {
                type: 'speedDateStart',
                sender: currentUser,
                receiver: date.sender,
                status: 'waiting',
                roomUrl,
                token,
                timestamp: new Date()
            });

            // Navigate to the SpeedDatePage
            navigate('/speedDatePage', { state: { roomUrl, identity: currentUserData.profile.username, token, speedDateId: date.id } });
        } catch (error) {
            console.error('UpcomingSpeedDate: Error starting speed date: ', error);
            alert('Failed to start speed date. Please try again.');
        }
    };

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h5" align="center">Upcoming Speed Dates</Typography>
            <List>
                {upcomingDates.map((date) => (
                    <ListItem key={date.id} alignItems="flex-start">
                        <Avatar src={date.senderProfilePicture} alt={date.senderUsername} sx={{ marginRight: 2 }} />
                        <ListItemText
                            primary={`SpeedDate with ${date.senderUsername}`}
                            secondary={
                                <>
                                    <Typography variant="body2">
                                        Date: {date.date}, Time: {date.time}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleStartSpeedDate(date)}
                                        sx={{ backgroundColor: "brown", mr: 2 }}
                                    >
                                        Start SpeedDate
                                    </Button>
                                </>
                            }
                        />
                    </ListItem>
                ))}

                {userSentUpcomingDates.map((date) => (
                    <ListItem key={date.id} alignItems="flex-start">
                        <Avatar src={date.receiverProfilePicture} alt={date.receiverUsername} sx={{ marginRight: 2 }} />
                        <ListItemText
                            primary={`SpeedDate with ${date.receiverUsername}`}
                            secondary={
                                <>
                                    <Typography variant="body2">
                                        Date: {date.date}, Time: {date.time}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: "brown", mr: 2 }}
                                        disabled
                                    >
                                        Accepted
                                    </Button>
                                </>
                            }
                        />
                    </ListItem>
                    ))}
            </List>
        </Box>
    );
}

export default UpcomingSpeedDates;

