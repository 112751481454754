/**
 * A higher-order component (HOC) that checks if a user is logged in before rendering the page.
 * If the user is not logged in, you can redirect them to the login page.
 */
import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Navigate } from 'react-router-dom';

function withAuth(Component) {
    return function AuthenticatedComponent(props) {
        const [isLoggedIn, setIsLoggedIn] = useState(null);
        const auth = getAuth();

        useEffect(() => {
            onAuthStateChanged(auth, (user) => {
                if(user) {
                    setIsLoggedIn(true);
                } else {
                    setIsLoggedIn(false);
                }
            });
        }, [auth]);

        if (isLoggedIn === null) {
            //Show a loading state while checking
            return <div>Loading...</div>;
        }

        return isLoggedIn ? <Component {...props} /> : <Navigate to="/" />;
    };
}

export default withAuth;