/**
 * Modal that pops up as notification when user recieves RizzUp request
 */
import React from 'react';
import { Modal, Box, Typography, Button } from "@mui/material";

const NotificationModal = ({ open, onClose, message }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ p: 4, backgroundColor: 'white', borderRadius: 2, maxWidth: 400,
            mx: 'auto', mt: '20%', marginLeft: '2%', marginRight:'2%' }}>
                <Typography variant="h6" gutterBottom>
                    New Notification
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {message}
                </Typography>
                <Button variant="contained" onClick={onClose} sx={{ mt: 2 }}>
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export default NotificationModal;