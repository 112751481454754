import React from 'react';
import ChatList from '../components/ChatList';

// Chats page to display the chat list
function Matches() {
    return (
        <div>
            <ChatList />
        </div>
    );
}

export default Matches;