import React, { useState, useEffect } from "react";
import { Box, List, ListItem, ListItemText, Typography, Avatar, Badge } from "@mui/material";
import { getAuth } from 'firebase/auth';
import {collection, query, where, onSnapshot, getDoc, getDocs, doc} from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from "react-router-dom";

// Utility function to generate consistent chat ID
const generateChatId = (uid1, uid2) => {
    return [uid1, uid2].sort().join('_');
};

// ChatList component to display list of chats
function ChatList() {
    const [chats, setChats] = useState([]);
    // Number of unread messages
    const [unreadCounts, setUnreadCounts] = useState({});
    const auth = getAuth();
    const currentUser = auth.currentUser.uid;
    const [currentUserName, setCurrentUserName] = useState('');
    // To help navigate to the Chat component page that shows chat between 2
    const navigate = useNavigate();

    useEffect(() => {
        const chatsRef = collection(db, 'connections');
        const q = query(chatsRef, where('users', 'array-contains', currentUser));

        // Real-time listener for chat connections
        const unsubscribe = onSnapshot(q, async(querySnapshot) => {
            const chatsData = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
            setChats(chatsData);

            // // Update unread message count for each chat
            const newUnreadCounts = {};
            for (const chat of chatsData) {
                //const otherUser = chat.user1 === currentUser ? chat.user2 : chat.user1;
                const lastReadField = chat.user1 === currentUser ? 'lastReadUser1' : 'lastReadUser2';

                // Set up real-time listener for each chat's messages
                const messagesRef = collection(db, 'chats', chat.id, 'messages');
                onSnapshot(messagesRef, async (messagesSnapshot) => {
                    const unreadMessages = messagesSnapshot.docs.filter(doc => {
                        const message = doc.data();
                        return message.timestamp?.seconds > chat[lastReadField]?.seconds;
                    });

                    // Update unread counts based on new messages
                    newUnreadCounts[chat.id] = unreadMessages.length;
                    setUnreadCounts(newUnreadCounts); // Ensure the state updates in real-time
                });
            }
        });

        return () => unsubscribe();
    }, [currentUser]);



    // Function to handle chat selection and navigate to chat page
    const handleChatSelect = (chat) => {
        console.log('ChatList: Chat = ', chat);
        // Create unique chatId based of user IDs for consistency
        const chatId = generateChatId(chat.user1, chat.user2);
        navigate('/chat', {
            state: {
                chatId,
                user1: chat.user1,
                user2: chat.user2,
                user2Username: chat.user2Username,
                user1Avatar: chat.user1Avatar,
                user2Avatar: chat.user2Avatar
            }
        });
    };

    /* const handleChatSelect = (chat) => {
        navigate('/profile');
    }; */

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h5">Chats</Typography>
            <List>
                {chats.map((chat) => {
                    // Determine the other user in the chat
                    const otherUser = chat.user1 === currentUser ? chat.user2 : chat.user1;
                    const otherUserAvatar = chat.user1 === currentUser ? chat.user2Avatar : chat.user1Avatar;
                    const otherUserName = chat.user1 === currentUser ? chat.user2Username : chat.user1Username;
                    const unreadCount = unreadCounts[chat.id] || 0;
                    console.log('ChatList: currentUserName = ', currentUserName);
                    console.log('ChatList: unreadCount = ', unreadCount);
                        return (
                            <ListItem button key={chat.id} onClick={() => handleChatSelect(chat)}>
                                <Badge badgeContent={unreadCount} color="secondary">
                                    <Avatar src={otherUserAvatar} alt="user avatar"/>
                                </Badge>
                                <ListItemText primary={otherUserName}/>
                            </ListItem>
                        );
            })}
            </List>
        </Box>
    );
}

export default ChatList;