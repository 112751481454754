import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardMedia, Typography, IconButton, Button } from '@mui/material';
import { motion } from 'framer-motion'; // To help with card swipe animation
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ShutterSpeedOutlinedIcon from '@mui/icons-material/ShutterSpeedOutlined';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

function ProfileCard({profile, onHeart, onMightVibe, onRizzUp, onSpeedDate, onSwipe }) {
    // If user liked profile
    const [liked, setLiked] = useState(false);
    const [isMaybeDisabled, setIsMaybeDisabled] = useState(false);
    const auth = getAuth();
    const currentUser = auth.currentUser.uid;
    const [isCupidHeartClicked, setIsCupidHeartClicked] = useState(false);
    // Track whose profile the rizzUp got clicked to reduce having to save and check database
    const [clickedCupidProfile, setClickedCupidProfile] = useState({
        profileUid: ''
    });

    //console.log('ProfileCard: profile.uid1 = ', profile.uid);

    // Check if profile is liked
    useEffect(() => {

        const checkIfLiked = async () => {
            const likeDoc = await getDoc(doc(db, 'likes', `${currentUser}_${profile.uid}`));
            const likeDocData = likeDoc.data();
            // If doc exists
            if (likeDoc.exists() && likeDocData.liked) {
                setLiked(true);
            } else {
                // Ensure it resets to false if no like exists
                setLiked(false);
            }
        };

        const checkIfMaybed = async () => {
            const maybeDoc = await getDoc(doc(db, 'maybes', `${currentUser}_${profile.uid}`));
            if (maybeDoc.exists()) {
                setIsMaybeDisabled(true);
            } else {
                setIsMaybeDisabled(false);
            }
        };

        checkIfLiked();
        checkIfMaybed();
    }, [currentUser, profile.uid]);

    const handleLike = async () => {
        setLiked(!liked);

        console.log('ProfileCard: profile.uid = ', profile.uid);

        const likeDocRef = doc(db, 'likes', `${currentUser}_${profile.uid}`);

        if (!liked) {
            await setDoc(likeDocRef, {
                sender: currentUser,
                receiver: profile.uid,
                timestamp: new Date(),
                liked: true
            });
            onHeart(profile); // Notify liked user
        } else {
            await updateDoc(likeDocRef, {liked: false});
            // Optional: add a function to unlike and remove the document
        }

        console.log("ProfileCard: HandleLiked Liked ? = ", liked);
    };

    const handleMaybe = async () => {
        setIsMaybeDisabled(true);
        const maybeDocRef = doc(db, 'maybes', `${currentUser}_${profile.uid}`);
        await setDoc(maybeDocRef, {
            sender: currentUser,
            receiver: profile.uid,
            timestamp: new Date()
        });
        // Disable the maybe button for that profile
        setIsMaybeDisabled(true);
        onMightVibe(profile);
    };

    // Threshold for swipe detection
    const swipeThreshold = 100;

    // Function to handle swipe action
    const handleDragEnd = (event, info) => {
        if (info.offset.x > swipeThreshold) {
            onSwipe('Right'); // Handle swipe right
        } else if (info.offset.x < -swipeThreshold) {
            onSwipe('Left'); // Handle swipe left
        }
    };

    // Track If cupid heart is clicked, so it shows the filled one
    const handleCupidsArrowClick = () => {
        // Set to true
        setIsCupidHeartClicked(true);
        // Set to the profile UID of clicked
        setClickedCupidProfile({
            profileUid: profile.uid
        });
    }

    // Function to track and check if clickedCupidHeart profile is current profile
    const handleCheckCupidHeartProfile = () => {
        return profile.uid === clickedCupidProfile.profileUid;
    }

    return (
        <motion.div
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            onDragEnd={handleDragEnd}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.5 }}
            >
        <Card sx={{ maxWidth: 345 }}>
            <Link to={`/profile/${profile.uid}`} style={{ textDecoration: 'none' }}>
                <CardMedia component="img" height="300" image={profile.imageUrl}
                           alt={profile.username}/>
            </Link>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {profile.username}, {profile.age}
                </Typography>
                 <Typography gutterBottom variant="body2" color="text.secondary">
                    {profile.city}, {profile.region}
                </Typography>
                <Typography gutterBottom variant="body2" color="text.secondary">
                   Common Interests: {Array.isArray(profile.commonInterests) ? profile.commonInterests.join(', '): 'N/A'}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <IconButton onClick={handleLike}>
                        {liked ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon color="error" />}
                    </IconButton>
                    <IconButton onClick={handleMaybe} disabled={isMaybeDisabled}>
                        {isMaybeDisabled ? <HelpOutlineIcon sx={{ color:'grey'}} /> : <HelpOutlineIcon color="primary" />}
                    </IconButton>
                    <IconButton onClick={() => { onRizzUp(profile); handleCupidsArrowClick(); }}>
                        {/* Cupids arrow emoji */}
                        { isCupidHeartClicked && clickedCupidProfile.profileUid === profile.uid ? (
                            <img
                                src="/images/cupidFilled.png"
                                alt="RizzUp"
                                style={{ width: '28px', height: '28px'}}
                                />
                        ) : (
                            <img
                            src="/images/cupid.png"
                            alt="RizzUp"
                            style={{ width: '28px', height: '28px'}}
                            />
                        )}
                    </IconButton>
                </Box>
                {/* Button for requesting a speed date */}
                <Button variant="contained" startIcon={<ShutterSpeedOutlinedIcon/>}
                onClick={() => onSpeedDate(profile)}
                sx={{ mt: 2, backgroundColor: '#800020', color: '#FFFFF0'}}>
                    Speed Date
                </Button>
            </CardContent>
        </Card>
        </motion.div>
    );
}

export default ProfileCard;