import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Button, Avatar } from "@mui/material";
import { getAuth } from 'firebase/auth';
import {collection, query, where, onSnapshot, updateDoc, doc, setDoc, getDoc, addDoc} from "firebase/firestore";
import { db } from '../firebase';
import { useNavigate } from "react-router-dom";

// Backend url using ngrok for daily.co
const DAILY_API_URL = 'https://blackdate-9f53e19d72a9.herokuapp.com';
// Utility function to generate consistent chat ID
const generateChatId = (uid1, uid2) => {
    return [uid1, uid2].sort().join('_');
};

// Notifications component to display icoming connection requests
function Notifications() {
    const [requests, setRequests] = useState([]);
    // State to track the requests current user sent to others
    const [userSentRequests, setUserSentRequests] = useState([]);
    const [speedDateRequests, setSpeedDateRequests] = useState([]);
    const [userSentSpeedDateR, setUserSentSpeedDateR] = useState([]);
    const [videoCallNotifications, setVideoCallNotifications] = useState([]);
    const auth = getAuth();
    const currentUser = auth.currentUser.uid;
    const navigate = useNavigate();

    useEffect(() => {
        // Reference to the requests collection in Firestore
        const requestsRef = collection(db, 'requests');
        // Query to get pending requests for the current user
        const q = query(requestsRef, where('receiver', '==', currentUser),
            where('status', '==', 'pending'));
        // Query to get pending requests sent by user
        const qPending = query(requestsRef, where('sender', '==', currentUser),
            where('status', '==', 'pending'));
        // Set up a real-time listener for the query
        const unsubscribe = onSnapshot(q, async(querySnapshot) => {
            // Map query results to an array of request objects
            const requestsData = await Promise.all(querySnapshot.docs.map(async (requestDoc) => {
                const requestData = requestDoc.data();
                const senderRef = doc(db, 'users', requestData.sender);
                const senderSnap = await getDoc(senderRef);
                const senderData = senderSnap.data();
                return {
                    id: requestDoc.id,
                    ...requestData,
                    senderUsername: senderData.profile.username, //Assuming username is in profile
                    senderProfilePicture: senderData.profilePicture // Assuming profile pic url is directly in user document
                };
            }));
            setRequests(requestsData);
        });

        // Set real Time listener for the pending requests sent by user to show current user in their notifications
        const unsubscribeSent = onSnapshot(qPending, async(querySnapshotP) => {
            // Map query results to an array of pendingRequests sent by user objects
            const sentRequestsData = await Promise.all(querySnapshotP.docs.map(async (sentRequestDoc) => {
                const sentRequestData = sentRequestDoc.data();
                const receiverRef = doc(db, 'users', sentRequestData.receiver);
                const receiverSnap = await getDoc(receiverRef);
                const receiverData = receiverSnap.data();
                return {
                    id: sentRequestDoc,
                    ...sentRequestData,
                    receiverUsername: receiverData.profile.username,
                    receiverProfilePicture: receiverData.profilePicture
                };
            }));
            setUserSentRequests(sentRequestsData);
        });

        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe();
            unsubscribeSent();
        }
    }, [currentUser]);

    // For speedDateRequests
    useEffect(() => {
        // Reference to the speed date requests collection in Firestore
        const speedDateRequestsRef = collection(db, 'speedDateRequests');
        // Query to get pending speed date requests for the current user
        const q = query(speedDateRequestsRef, where('receiver', '==', currentUser),
            where('status', '==', 'pending'));
        const qPending = query(speedDateRequestsRef, where('sender', '==', currentUser),
            where('status', '==', 'pending'));

        // Real time listener
        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const requestsData = await Promise.all(querySnapshot.docs.map(async (requestDoc) => {
                const requestData = requestDoc.data();
                const senderRef = doc(db, 'users', requestData.sender);
                const senderSnap = await getDoc(senderRef);
                const senderData = senderSnap.data();
                return {
                    id: requestDoc.id,
                    ...requestData,
                    senderUsername: senderData.profile.username,
                    senderProfilePicture: senderData.profilePicture
                };
            }));
            setSpeedDateRequests(requestsData);
        });

         // Set real Time listener for the pending requests sent by user to show current user in their notifications
        const unsubscribeSent = onSnapshot(qPending, async(querySnapshotP) => {
            // Map query results to an array of pendingRequests sent by user objects
            const sentRequestsData = await Promise.all(querySnapshotP.docs.map(async (sentRequestDoc) => {
                const sentRequestData = sentRequestDoc.data();
                const receiverRef = doc(db, 'users', sentRequestData.receiver);
                const receiverSnap = await getDoc(receiverRef);
                const receiverData = receiverSnap.data();
                return {
                    id: sentRequestDoc,
                    ...sentRequestData,
                    receiverUsername: receiverData.profile.username,
                    receiverProfilePicture: receiverData.profilePicture
                };
            }));
            setUserSentSpeedDateR(sentRequestsData);
        });


        return () => {
            unsubscribe();
            unsubscribeSent();
        }
    }, [currentUser]);

    // For Video Call notifications
    useEffect(() => {
        // Reference to the notifications collection in Firestore
        const notificationsRef = collection(db, 'notifications');
        // Query to get speed date start notifications for the current user
        const q = query(notificationsRef, where('receiver', '==', currentUser),
            where('status', '==', 'waiting'));

        // Real time listener
        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const notificationsData = await Promise.all(querySnapshot.docs.map(async (dateDoc) => {
                const notificationData = dateDoc.data();
                const senderRef = doc(db, 'users', notificationData.sender);
                const senderSnap = await getDoc(senderRef);
                const senderData = senderSnap.data();
                return {
                    id: dateDoc.id,
                    ...notificationData,
                    senderUsername: senderData.profile.username,
                    senderProfilePicture: senderData.profilePicture
                };
            }));
            setVideoCallNotifications(notificationsData);
        });

        return () => unsubscribe();
    }, [currentUser]);

    // Function to handle acceptence of RizzUp Requests
    const handleAcceptRizzUp = async (request) => {
        try {
            // Reference to the specific request document in Firestore
            const requestRef = doc(db, 'requests', request.id);
            // Update the status of the request to 'accepted'

            await updateDoc(requestRef, {
                status: 'accepted'
            });

            const chatId = generateChatId(request.sender, request.receiver);

            // Fetch current user's profile data
            const currentUserRef = doc(db, 'users', currentUser);
            const currentUserSnap = await getDoc(currentUserRef);
            const currentUserData = currentUserSnap.data();

            // Add a new Document to the connections collection for the accepted request
            const connectionsRef = doc(db, 'connections', chatId);
            await setDoc(connectionsRef, {
                users: [request.sender, request.receiver],
                user1: request.sender,
                user2: request.receiver,
                user1Username: request.senderUsername,
                user2Username: currentUserData.profile.username,
                user1Avatar: request.senderProfilePicture,
                user2Avatar: currentUserData.profilePicture,
                chatName: request.senderUsername,
                timestamp: new Date()
            });

            console.log(`Notifications: Accepted request from ${request.sender}`);
        } catch (error) {
            console.error('Notifications: Error accepting request: ', error);
        }
    };

    const handleRejectRizzUp = async (request) => {
        try {
            // Reference to the specific request document in Firestore
            const requestRef = doc(db, 'requests', request.id);
            // Update the status of the request to 'rejected'
            await updateDoc(requestRef, {
                status: 'rejected'
            });

            console.log(`Notifications: Rejected Rizz request from ${request.sender}`);
        } catch (error) {
            console.error('Notifications: Error rejecting rizz request: ', error);
        }
    };

    const handleAcceptSpeedDate = async (request) => {
        try {
            const requestRef = doc(db, 'speedDateRequests', request.id);
            await updateDoc(requestRef, {
                status: 'accepted'
            });

            // Notify sender of acceptance
            const notificationRef = collection(db, 'notifications');
            await addDoc(notificationRef, {
                type: 'speedDateAccepted',
                sender: currentUser,
                receiver: request.sender,
                timestamp: new Date()
            });

            console.log(`Notifications: Accepted speed date request from ${request.sender}`);
        } catch (error) {
            console.error('Notifications: Error accepting speed date request: ', error);
        }
    };

    const handleRejectSpeedDate = async (request) => {
        try {
            const requestRef = doc(db, 'speedDateRequests', request.id);
            await updateDoc(requestRef, {
                status: 'rejected'
            });


            console.log(`Notifications: Rejected speed date request from ${request.sender} `);
        } catch (error) {
            console.error('Notifications: Error rejecting speed date request', error);
        }
    };

    // Function to handle starting a speed date video call
    const handleStartSpeedDate = async (notification) => {
        console.log('handleStartSpeedDate: notification = ', notification);

        // Fetch current user's profile data
        const currentUserRef = doc(db, 'users', currentUser);
        const currentUserSnap = await getDoc(currentUserRef);
        const currentUserData = currentUserSnap.data();

        try {
            // Update the speedDate Notification status to joined to remove from notifications
            const notificationRef = doc(db, 'notifications', notification.id);
            // Update the status to joined
            await updateDoc(notificationRef, {
                status: 'joined'
            });

            console.log('Notifications: Updated Video Call notification status to Joined for: ', currentUserData.profile.username);
        } catch (error) {
            console.error('Notifications: Error updating VideoCall notification status to joined');
        }

        // Navigate to the SpeedDatePage with the room name and token for authentication
        navigate('/speedDatePage', {
            state: {
                roomUrl: notification.roomUrl,
                identity: currentUserData.profile.username,
                token: notification.token
            }
        });
    }

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h6" align="center">RizzUp Requests</Typography>
            <List>
                {requests.map((request) => (
                    <ListItem key={request.id} alignItems="flex-start">
                        {/* Avatar to display sender's profile pic*/ }
                        <Avatar src={request.senderProfilePicture} alt={request.senderUsername} sx={{ marginRight: 2 }} />
                        <ListItemText
                            primary={`${request.senderUsername}`}
                            secondary={
                                <>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleAcceptRizzUp(request)}
                                        sx={{ mr: 2 }}
                                        >
                                        Rizz Me Up
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => handleRejectRizzUp(request)}
                                        >
                                        Reject Rizz
                                    </Button>
                                </>
                            }
                        />
                    </ListItem>
                ))}
                {/* Show current user who they sent a request to */}
                {userSentRequests.map((sentRequest) => (
                    <ListItem key={sentRequest.id} alignItems="flex-start">
                        {/* Avatar to display sender's profile pic */}
                        <Avatar src={sentRequest.receiverProfilePicture} alt={sentRequest.receiverUsername} sx={{ marginRight: 2 }} />
                        <ListItemText
                            primary={`To ${sentRequest.receiverUsername}`}
                            secondary={
                                <>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled
                                        sx={{ mr: 2 }}
                                        >
                                        Pending...
                                    </Button>
                                </>
                            }
                            />
                    </ListItem>
                ))}
            </List>

            <Typography variant="h6" align="center">SpeedDate Requests</Typography>
            <List>
                {speedDateRequests.map((request) => (
                    <ListItem key={request.id} alignItems="flex-start">
                        <Avatar src={request.senderProfilePicture} alt={request.senderUsername}
                                sx={{ marginRight: 2 }} />
                        <ListItemText
                            primary={` ${request.senderUsername}`}
                            secondary = {
                            <>
                                <Typography variant="body2">
                                    Date: {request.date}, Time: {request.time}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleAcceptSpeedDate(request)}
                                    sx={{ mr: 2 }}
                                    >
                                    Accept
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleRejectSpeedDate(request)}
                                    >
                                    Decline
                                </Button>
                            </>
                            }
                            />
                    </ListItem>
                ))}
                {/* Show Users their pending speedDate Requests */}
                {userSentSpeedDateR.map((sentSpeedDate) => (
                    <ListItem key={sentSpeedDate.id} alignItems="flex-start">
                        {/* Avatar to display sender's profile pic */}
                        <Avatar src={sentSpeedDate.receiverProfilePicture} alt={sentSpeedDate.receiverUsername} sx={{ marginRight: 2 }} />
                        <ListItemText
                            primary={`To ${sentSpeedDate.receiverUsername}`}
                            secondary={
                                <>
                                    <Typography variant="body2">
                                        Date: {sentSpeedDate.date}, Time: {sentSpeedDate.time}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled
                                        sx={{ mr: 2 }}
                                        >
                                        Pending...
                                    </Button>
                                </>
                            }
                            />
                    </ListItem>
                ))}
            </List>
            <Typography variant="h6" align="center">Video Call Notifications</Typography>
            <List>
                {videoCallNotifications.map((notification) => (
                    <ListItem key={notification.id} alignItems="flex-start">
                        <Avatar src={notification.senderProfilePicture} alt={notification.senderUsername}
                                sx={{ marginRight: 2 }} />
                        <ListItemText
                            primary={`SpeedDate Video Call from ${notification.senderUsername}`}
                            secondary={
                                <>
                                    <Typography variant="body2">
                                        Room: {Notification.roomName}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleStartSpeedDate(notification)}
                                        sx={{ mr: 2, backgroundColor: "brown"}}
                                        >
                                        Join Call
                                    </Button>
                                </>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}

export default Notifications;