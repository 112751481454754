import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import VideoCall from './VideoCall'; // Import videoCall component
import { VideoCallContext } from "../context/VideoCallContext";
import { AccessAlarm } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";

// SpeedDatePage component to render the speed date video call
function SpeedDatePage() {
    const location = useLocation();
    // Fetch the room URL, user's identity, and token from the location state
    const { roomUrl, identity, token, speedDateId } = location.state;
    // To update state if user in video call to hide header and footer
    const { setInVideoCall } = useContext(VideoCallContext);
    // Time for speeddate according to psychology, 10min best in sec
    const [timeLeft, setTimeLeft] = useState(600);
    // To show warning when 1 Minute is left
    const [showWarning, setShowWarning] = useState(false);

    //console.log('SpeedDatePage: RoomURL = ', roomUrl);
    console.log('SpeedDatePage: Identity = ', identity);
    //console.log('SpeedDatePage: Token = ', token);

    useEffect(() => {
        // Set inVideoCall to true when the component mounts
        setInVideoCall(true);

        return () => {
            // Set inVideoCall to false when the component unmounts
            setInVideoCall(false);
        };
    }, [setInVideoCall]);

    // Update and stop video call when time is up
    /*useEffect(() => {
        if (timeLeft === 0) {
            alert('Time is up!');
        }
    }, [timeLeft]); */

    return (
        <div>
            {/* Render the VideoCall component */}
            <VideoCall roomUrl={roomUrl}
                       token={token}
                       identity={identity}
                       timeLeft={timeLeft}
                       setTimeLeft={setTimeLeft}
                       showWarning={showWarning}
                       setShowWarning={setShowWarning}
                       speedDateId={speedDateId}
            />

            {/* Timer Overlay */}
            <div className="overlay overlay-bottom-right">
                <AccessAlarm className="icon" />
                <span>{Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}</span>
            </div>

            {/* Warning Dialog */}
            <Dialog open={showWarning} onClose={() => setShowWarning(false)}>
                <DialogTitle>1 Minute Left</DialogTitle>
                <DialogContent>
                    You have 1 minute left in this Speed Date
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default SpeedDatePage;