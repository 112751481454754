import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Modal, Link } from '@mui/material';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
function RegisterModal({ open, onClose, onLoginOpen }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isRegistered, setIsRegistered] = useState(false);
    const [error, setError] = useState('');
    const [registering, setRegistering] = useState(false);
    const navigate = useNavigate();

    const handleRegister = async () => {
        // Make sure password is > 6 characters
        if (password.length < 6) {
            setError('Password must be at least 6 character.');
            return;
        }

        // Set registering to true
        setRegistering(true);

        // Handle registration logic
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            console.log('Register: ', user);
            // Save user profile to Firestore
            await setDoc(doc(db, 'users', user.uid), {
                name,
                email
            });
            // Log out the user immediately after registration
            await signOut(auth);

            //Then set userRegistered
            setIsRegistered(true);
        } catch (error) {
            setRegistering(false);
            console.error('Error during registration:', error);
            setError('Registration failed. Please try again.');
        }
    };

    return (
        <Modal open={open} onClose={onClose} align="center">
            <Box sx={{ p: 4, maxWidth: 400, mx: 'auto', mt: 8, backgroundColor: 'white', borderRadius: 2,
             marginLeft: '2%', marginRight: '2%'}}>
              <Typography variant="h4" align="center" gutterBottom>
                Register
              </Typography>
                {error && <Typography color="error">{error}</Typography>}
              <TextField
                label="Name"
                fullWidth
                margin="normal"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                label="Email"
                type="email"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Password"
                type="password"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
                {isRegistered && (
                  <Button variant="contained" fullWidth onClick={onLoginOpen} sx={{ mt: 2, backgroundColor: 'brown',}}>
                    Login
                  </Button>
                    )}
                {!isRegistered && (
                  <Button variant="contained" fullWidth onClick={handleRegister} sx={{ mt: 2, backgroundColor: 'brown',}}
                   disabled={registering}>
                      { registering ? 'Registering...' : 'Register' }
                  </Button>
                    )}
                <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                    <Link component="button" variant="body2" onClick={onLoginOpen}>
                        Login here
                    </Link>
                </Typography>
            </Box>
        </Modal>
    );
}

export default RegisterModal;