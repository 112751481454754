import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import Home from './pages/Home';
import Link from './pages/Link';
import Matches from './pages/Matches';
import Speed from './pages/SpeedDate';
import Profile from './pages/Profile';
import ProfileSetup from './pages/ProfileSetup';
import ProfilePictureUpload from "./pages/ProfilePictureUpload";
import Chat from './components/Chat';
import SpeedDatePage from './components/SpeedDatePage';
import ChatPage from './components/ChatPage';
import Dashboard from './pages/Dashboard';
import FooterNav from './components/FooterNav';
import Header from './components/Header';
import withAuth from './components/withAuth';
import AdminDashboard from './pages/AdminDashboard';
import { VideoCallProvider, VideoCallContext } from "./context/VideoCallContext";
import './App.css';

// To wrap up checking if user is logged in and redirect to homepage
const AuthenticatedLink = withAuth(Link);
const AuthenticatedMatches = withAuth(Matches);
const AuthenticatedProfile = withAuth(Profile);
const AuthenticatedChatPage = withAuth(ChatPage);
const AuthenticatedDashboard = withAuth(Dashboard);
const AuthenticatedSpeed = withAuth(Speed);
const AuthenticatedSpeedDatePage = withAuth(SpeedDatePage);
const AuthenticatedAdminDashboard = withAuth(AdminDashboard);

function App() {

      return (
          <VideoCallProvider>
              <VideoCallContext.Consumer>
                  {({ inVideoCall }) => (
                      <Router>
                          {!inVideoCall && <Header />}
                          <div style={{ paddingBottom: !inVideoCall ? '56px' : '0' }}> {/* Adjust padding to footer height */}
                            <Routes>
                                <Route path="/" element={<Home/>} />
                                <Route path="/link" element={<AuthenticatedLink />} />
                                <Route path="/matches" element={<AuthenticatedMatches/>} />
                                <Route path="/profile" element={<AuthenticatedProfile/>} />
                                {/* Add a new route for viewing other users profiles */}
                                <Route path="/profile/:userId" element={<AuthenticatedProfile/>} />
                                <Route path="/profile-setup" element={<ProfileSetup />} />
                                <Route path="/profile-picture-upload" element={<ProfilePictureUpload />} />
                                <Route path="/chat" element={<AuthenticatedChatPage />} />
                                <Route path="/dashboard" element={<AuthenticatedDashboard />} />
                                <Route path="/speed" element={<AuthenticatedSpeed/>} />
                                <Route path="/speedDatePage" element={<AuthenticatedSpeedDatePage />} />
                                <Route path="/admin" element={<AuthenticatedAdminDashboard/>} />
                            </Routes>
                          </div>
                          {!inVideoCall && <FooterNav />}
                      </Router>
                  )}
              </VideoCallContext.Consumer>
          </VideoCallProvider>
      );
    }

export default App;
