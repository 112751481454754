import React from 'react';
import AppBar from '@mui/material/AppBar';
import ToolBar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

function Header() {
    return (
        <AppBar position="static" sx={{ backgroundColor: '#865D36' }}>
            <ToolBar>
                <Typography variant="h6" sx={{ color: '#FFD700'}}>
                    BlackDate
                </Typography>
            </ToolBar>
        </AppBar>
    );
}

export default Header;
