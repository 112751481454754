import React from 'react';
import { useLocation } from 'react-router-dom';
import Chat from './Chat'; // Adjust the import path if necessary

// ChatPage component to display the Chat component
function ChatPage() {
    const location = useLocation();
    const { chatId, user1, user2, user1Username, user2Username, user1Avatar, user2Avatar } = location.state || {}; // Destructure the state with a fallback to an empty object

    console.log('ChatPage received state:', location.state);

    return (
        <Chat
            chatId={chatId}
            user1={user1}
            user2={user2}
            user1Username={user1Username}
            user2Username={user2Username}
            user1Avatar={user1Avatar}
            user2Avatar={user2Avatar}
            />
    );
}

export default ChatPage;