import React, { useEffect, useRef} from 'react';
import { DailyProvider, useCallFrame } from '@daily-co/daily-react';
import IconButton from '@mui/material/IconButton';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { useNavigate } from 'react-router-dom';
import speedDate from "../pages/SpeedDate";
import {db} from "../firebase";
import {updateDoc, doc} from "firebase/firestore";

// To update later
const DAILY_API_URL = 'https://blackdate-9f53e19d72a9.herokuapp.com'; // Your server endpoint URL

// VideoCall component for handling video calls using Daily.co
function VideoCall({ roomUrl, token, identity, timeLeft, setTimeLeft, setShowWarning, speedDateId }) {
    //console.log('VideoCall: RoomURL = ', roomUrl);
    //console.log('VideoCall: Token = ', token);
    // To use to attach DailyIFrame to the DOM
    const videoCallRef = useRef(null);
    const navigate = useNavigate();
    // useCallFrame hook to manage the creation and lifecycle of DailyIFrame
    const callFrame = useCallFrame({
        parentElRef: videoCallRef,
        options: {
            url: roomUrl, // URL of the room to join
            token: token, // Authentication token for the room
            userName: identity,
            iframeStyle: { // Style for the iframe
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: '0',
            }
        },
    });

    // Initializing useNavigate to help navigate to profile page after call ends
    //const navigate = useNavigate();

    // Effect to join the call when the component mounts
    useEffect(() => {
         // Ensure that both roomUrl and token are defined
        if (!roomUrl || !token) {
            console.error('VideoCall: roomUrl or token is not defined');
            return;
        }
        if (!callFrame) return;

        // Join the call when the component mounts
        callFrame.join();

         // Timer variable
        let timer;
        const startTimer = () => {
            // Function to start the timer
            timer = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime === 61) {
                        // Show warning when 1 minute is left
                        setShowWarning(true);
                    }
                    if (prevTime <= 1) {
                        // Clear the timer
                        clearInterval(timer);
                        // Alert the users
                        //alert('Time is up!');
                        // Send message to end call
                        callFrame.sendAppMessage({ endCall: true });
                        // Leave the call
                        callFrame.leave();
                        // Update room status
                        updateRoomStatusToAvailable();
                        // Update speedDate to completed
                        endSpeedDate();
                        // Redirect to profile page
                        navigate('/profile');
                    }
                    // Decrement timeLeft by 1 second
                    return prevTime - 1;
                });
            }, 1000); // Update every second
        };

        // Start the timer after the first participant joins
        const handleJoinedMeeting = () => {
             // Start Timer
             startTimer();
             // Remove the listener after it fires once
            callFrame.off('joined-meeting', handleJoinedMeeting);
        };

        const handleParticipantJoined = (event) => {
            console.log('VideoCall: Participant Joined when timeLeft =', timeLeft);
            callFrame.sendAppMessage({ syncTimeLeft: timeLeft });
        };

        const handleAppMessage = (event) => {
            if (event.data?.endCall) {
                clearInterval(timer); // Stop the timer when the call ends
                // Leave the call if endCall message is received
                callFrame.leave();
                //Update speedDate to completed
                endSpeedDate();
                // Redirect to profile page
                navigate('/profile');
            }
            if (event.data?.syncTimeLeft !== undefined) {
                // Sync the timer with the received value for consistency
                setTimeLeft(event.data.syncTimeLeft);
            }
        };

        callFrame.on('joined-meeting', handleJoinedMeeting);
        callFrame.on('participant-joined', handleParticipantJoined);
        callFrame.on('app-message', handleAppMessage);

        // Function to update room status to available
        const updateRoomStatusToAvailable = async () => {
            try {
                const response = await fetch(`${DAILY_API_URL}/set-room-available`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ roomName: roomUrl.split('/').pop() }),
                });

                if (!response.ok) {
                    console.error('VideoCall.js: Failed to update room status:', response.statusText);
                } else {
                    console.log('VideoCall.js: Room status updated to available');
                }
            } catch (error) {
                console.error('VideoCall.js: Error updating room status:', error);
            }
        };

        const handleParticipantEvent = () => {
            const participants = callFrame.participants();
            console.log('VideoCall: Updated participants, total participants =', Object.keys(participants).length);
            if (Object.keys(participants).length === 0) {
                clearInterval(timer);
                updateRoomStatusToAvailable();
                // Update speed date to completed
                endSpeedDate();
                navigate('/profile');
            }
        };

        callFrame.on('participant-left', handleParticipantEvent);
        callFrame.on('left-meeting', handleParticipantEvent);

        return () => {
            // Clear the timer when the component unmounts
            clearInterval(timer);
            callFrame.on('joined-meeting', handleJoinedMeeting);
            callFrame.off('participant-joined', handleParticipantJoined);
            callFrame.off('participant-left', handleParticipantEvent);
            callFrame.off('left-meeting',handleParticipantEvent);
            callFrame.off('app-message', handleAppMessage);
            if (callFrame) {
                callFrame.leave();
                // Redirect to profile page
                // navigate('/profile');
            }
        };
    }, [callFrame, roomUrl, token, setTimeLeft, setShowWarning]);

    // Function to end the video call speedDate
    const handleEndCall = () => {
        callFrame.sendAppMessage({ endCall: true });
        callFrame.leave();
        // Redirect to profile page
        // Update speed date to completed
        endSpeedDate();
        // navigate('/profile');
    };

    // Function to mark the speed date as completed in Firestore
    const endSpeedDate = async () => {
        try {
            // Mark speed date as completed in Firestore
            const speedDateRef = doc(db, 'speedDateRequests', speedDateId);
            await updateDoc(speedDateRef, { completed: true });
            console.log('Speed date marked as completed.');
        } catch (error) {
            console.error('VideoCall: endSpeedDate Error ending speed date and updating speeddate to completed in firestore: ', error);
        }
    };

    return (
        <DailyProvider callObject={callFrame} style={{ height: '100vh'}}>
            <div ref={videoCallRef} style={{ width: '100%', height: '100%' }} />
            <IconButton onClick={handleEndCall} style={{ position: 'fixed', bottom: '40px', right: '20px'}} color="secondary">
                <CallEndIcon />
            </IconButton>
        </DailyProvider>
    );
}

export default VideoCall;