/**
 * To show notifications to users
 */
import React from 'react';
import Notifications from '../components/Notifications';

function Dashboard() {
    return(
       <div>
           <Notifications />
           {/* Add other components as needed */}
       </div>
    );
}

export default Dashboard;