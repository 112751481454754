import React, { useState, useEffect } from 'react';
import { Modal, Container, Box, Typography, Button } from '@mui/material';
import LoginModal from '../components/LoginModal';
import RegisterModal from '../components/RegisterModal';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './Home.css';
import '../index.css';
import { useNavigate } from 'react-router-dom';

function Home() {
    const [loginOpen, setLoginOpen] = useState(false);
    const [registerOpen, setRegisterOpen] = useState(false);
    // To check if user is logged in or not
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [installModalOpen, setInstallModalOpen] = useState(false);
    const navigate = useNavigate();

    // Check if user is logged in from firestore
    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        });

    }, []);

    // Function to handle when user wants to login
    const handleLoginOpen = () => {
        setLoginOpen(true);
        setRegisterOpen(false);
    };

    // Function to handle when user wants to register
    const handleRegisterOpen = () => {
        setRegisterOpen(true);
        setLoginOpen(false);
    };

    // Get Linking button Click if user already logged in
    const handleBtnClickLoggenIn = () => {
        navigate('/link');
    }

     // Toggle install modal
    const handleInstallModalOpen = () => {
        setInstallModalOpen(true);
    };

    const handleInstallModalClose = () => {
        setInstallModalOpen(false);
    };

     // Modal styling
    const modalStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 4,
        marginTop: '40%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: 'white',
        transform: 'translateY(-50%)',
        borderRadius: '10px',
        '@media (max-width: 768px)': {
            marginTop: '70%', // This applies to screens below 768px width
        }
    };


    return (
        <div className="background">
        <Container maxWidth="lg" >
            {/* Hero Section */}
            <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Typography variant="h3" sx={{ paddingTop: '10%', color: '#FFFFF0', mb: 2 }}>
                    Welcome to BlackDate
                </Typography>
                <Typography variant="h5" sx={{ paddingTop: '5%', color: 'olivedrab', mb: 4 }}>
                    <b>Black Love, Black Joy, BlackDate!</b>
                </Typography>
                { !isLoggedIn && (
                <Button variant="contained" sx={{ marginTop: '10%', backgroundColor: 'brown', color: '#FFFFF0'}}
                onClick={handleLoginOpen}>
                    Get Linking
                </Button>
                    )}
                { isLoggedIn && (
                    <Button variant="contained" sx={{ marginTop: '10%', backgroundColor: 'brown', color: '#FFFFF0'}}
                            onClick={handleBtnClickLoggenIn}>
                        Get Linking
                    </Button>
                    )}
                    {/* Install App Button */}
            </Box>
            <Box>
                <center><Typography variant="h5" sx={{ bottom: '5%',  color: 'burlywood', mb: 4, paddingTop: '5%' }}><b>NZ, AUS</b></Typography></center>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
                <Button variant="contained" sx={{ marginTop: '30%', backgroundColor: 'cornflowerblue', color: '#FFFFF0'}}
                            onClick={handleInstallModalOpen}>
                        Install App
                </Button>
            </Box>

        </Container>

            <LoginModal open={loginOpen} onClose={() => setLoginOpen(false)} onRegisterOpen={handleRegisterOpen} />
            <RegisterModal open={registerOpen} onClose={() => setRegisterOpen(false)} onLoginOpen={handleLoginOpen} />
            {/* Install App Modal */}
            <Modal open={installModalOpen} onClose={handleInstallModalClose}>
                <Box sx={{ ...modalStyle, p: 4 }}>
                    <Typography variant="h6">Install as App</Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        This website can be installed as an app on your phone for a better experience.
                    </Typography>
                     <Typography variant="body2" sx={{ mt: 2 }}>
                        <b>For iOS (iPhone):</b> Tap the share button in Safari and then select "Add to Home Screen", and it will install app.
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        <b>For Android:</b> Open the browser menu and select "Add to Home screen." You should see "Install App".
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Enjoy the App-experience on your device!
                    </Typography>
                    <Button onClick={handleInstallModalClose} sx={{ mt: 2 }} variant="contained" color="primary">
                        Got it!
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}

export default Home;