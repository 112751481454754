import React, { useState, useEffect, useRef } from 'react';
import {Box, TextField, IconButton, Button, Typography, List, ListItem, Card, Avatar, CardContent} from "@mui/material";
import { getAuth } from 'firebase/auth';
import SendIcon from '@mui/icons-material/Send';
import { collection, addDoc, getDocs, query, onSnapshot, orderBy, limitToLast, endBefore, serverTimestamp, updateDoc, doc } from "firebase/firestore";
import { db } from '../firebase';
import CryptoJS from 'crypto-js'; // For message encrytion and decryption for users privacy
import { Link } from 'react-router-dom';

// Secret key to encode and decode messages(Came up with it myself)
// const chatEncodeSecretKey = process.env.REACT_APP_CHAT_ENCODE_SECRET_KEY;
const chatEncodeSecretKey = 'sG7v@xK9l!4wTzNpu2RbsQceMVn6nmDa';
// Chat component to display chat messages and input field
function Chat({ chatId, user1, user2, user2Username, user1Avatar, user2Avatar }) {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const typingTimeout = useRef(null)
    const [oldestVisible, setOldestVisible] = useState(null); // Track the oldest message currently loaded
    const [loadingEarlierMessages, setLoadingEarlierMessages] = useState(false); // Prevent multiple triggers of fetching
    const auth = getAuth();
    const currentUser = auth.currentUser.uid;
    // To know where messages end so user is taken there when they open chat
    const messagesEndRef = useRef(null);
    // To get the right chat avatar at the top
    const otherUserAvatar = user1 === currentUser ? user2Avatar : user1Avatar;
    // To help with knowing if there are new messages received
    const lastReadField = user1 === currentUser ? 'lastReadUser1' : 'lastReadUser2';
    //const otherUserName = user1 === currentUser ? user2Username : user1Username;
    const otherUser = user1 === currentUser ? user2 : user1 // Determine the correct user ID

    // Load the latest messages on component mount
    useEffect(() => {
        // Reference to the messages collection in  Firestore
        const messageRef = collection(db, 'chats', chatId, 'messages');
        // Query to get messages ordered by timestamp
        const q = query(messageRef, orderBy('timestamp', 'asc'), limitToLast(3)); // Load last 20 messages
        // Set up a real-time listener for the query
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            // Map query results to an array of message objects
            const messagesData = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));
            setMessages(messagesData);
            // Set the first message for pagination
            setOldestVisible(querySnapshot.docs[0]);
            // Scroll to the bottom when user opens chat
            scrollToBottom();
        });

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
    }, [chatId]);

    // To automatically scroll down to the bottom of the chats
    const scrollToBottom = () => {
        if (messagesEndRef.current) {
             messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            // messagesEndRef.current.parentNode.scrollTop = 724;
        }
    };

    // Function to load earlier messages when user scrolls up
    const loadEarlierMessages = async () => {
        console.log("Chat: loadEarlierMessages oldestVisible = ", oldestVisible);
        // Avoid redundant fetched
        if (!oldestVisible || loadingEarlierMessages) return;
        setLoadingEarlierMessages(true);

        const messageRef = collection(db, 'chats', chatId, 'messages');
        // Fetch earlier 6 messages
        const q = query(messageRef, orderBy('timestamp', 'asc'), endBefore(oldestVisible), limitToLast(6));
        const querySnapshot = await getDocs(q);
        const earlierMessages = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));
        console.log("Chat: loadEarlierMessages messages num = ", querySnapshot.size);
        // Prepend earlier messages to the current list
        setMessages(prevMessages => [...earlierMessages, ...prevMessages]);
        // Update the oldest visible message
        setOldestVisible(querySnapshot.docs[0]);
        // Unlock fetching
        setLoadingEarlierMessages(false);
    };

    // Detect when user scrolls to the top and load earlier messages
    const handleScroll = (e) => {
        console.log("Chat: handleScroll = ", e.target.scrollTop);
        if (e.target.scrollTop <= 20) { // Adjusted to 20px so it senses more for smoothness
            // Load earlier messages when scrolled to the top
            loadEarlierMessages();
        }
    };

    useEffect(() => {
        // Reference to the connections document in Firestore
        const typingRef = doc(db, 'connections', chatId);
        // Set up a real-time listener for typing status
        const unsubscribe = onSnapshot(typingRef, (doc) => {
            if (doc.exists()) {
                const data = doc.data();
                setIsTyping(data.typing && data.typing !== currentUser);
            }
        });

        return () => unsubscribe();
    }, [chatId, currentUser]);

    // To update LastRead field timestamp and count new messages
    useEffect(() => {
        const markAsRead = async () => {
            const chatRef = doc(db, 'connections', chatId);
            await updateDoc(chatRef, {
                [lastReadField]: serverTimestamp()
            });
        };

        markAsRead();

        // Update Last read timestamp when user leaves chat
        return () => {
            const updateLastRead = async () => {
                const chatRef = doc(db, 'connections', chatId);
                await updateDoc(chatRef, {
                    [lastReadField]: serverTimestamp()
                });
            };

            updateLastRead();
        };
    }, [chatId, lastReadField]);

    const handleSendMessage = async () => {
        if (message.trim()) {
            // Encrypt the message
            const encryptedMessage = encryptMessage(message);

            const messageRef = collection(db, 'chats', chatId, 'messages');
            await addDoc(messageRef, {
                text: encryptedMessage, // Save the encrypted message
                sender: currentUser,
                timestamp: serverTimestamp()
            });
            setMessage('');
            await updateDoc(doc(db, 'connections', chatId), {
                typing: '',
                [lastReadField]: serverTimestamp()
            });
            // Scroll to the bottom of the screen
            scrollToBottom();
        }
    };

    // Function to update the typing field in Firestore when the user is typing
    const handleTyping = async (e) => {
        setMessage(e.target.value);
       /* if (typingTimeout.current) {
            clearTimeout(typingTimeout.current);
        }
        await updateDoc(doc(db, 'connections', chatId), {
            typing: currentUser
        });
        typingTimeout.current = setTimeout(async () => {
            await updateDoc(doc(db, 'connections', chatId), {
                typing: ''
            });
        }, 3000); */
    };

    // To differentiate actual dates in human readable form
    const formatDate = (timestamp) => {
        if (!timestamp?.seconds) return '';
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleDateString('en-US', {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
    };

    // Encrypt Message
    const encryptMessage = (message) => {
        return CryptoJS.AES.encrypt(message, chatEncodeSecretKey).toString();
    };

    // Decrypt Message
    const decryptMessage = (cipherText) => {
        try {
            const bytes = CryptoJS.AES.decrypt(cipherText, chatEncodeSecretKey);
            return bytes.toString(CryptoJS.enc.Utf8);
        } catch (error) {
            console.error("Decryption error: ", error);
            return cipherText;  // Return original text if decryption fails
        }
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            {/* Sticky Avatar and Username Header */}
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                p: 2,
                borderBottom: '1px solid #ccc',
                marginBottom: '12%',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                zIndex: 10 // Keep it above the messages
            }}>
                <Link to={`/profile/${otherUser}`} style={{ textDecoration: 'none' }}>
                    <Avatar src={otherUserAvatar} alt={user2Username} sx={{ mr: 2 }} />
                    {/*<Typography variant="h6">{}</Typography>*/}
                </Link>
            </Box>

            {/* Messages List */}
            <List sx={{ flexGrow: 1, overflow: 'auto', paddingBottom: '50%' }}>
                { messages.length > 2 && (
                    <Button variant="outlined"
                            sx={{ color: 'brown',
                                borderColor: 'brown'
                            }}
                            onClick={loadEarlierMessages} disabled={loadingEarlierMessages}>
                        {loadingEarlierMessages ? 'Loading...' : 'Load Earlier Messages'}
                    </Button>
                    )}
                {messages.map((msg, index) => {
                    // Decrypt the text message before showing
                    let decryptedText = msg.text;

                    try {
                        // Try decrypting the message
                        decryptedText = decryptMessage(msg.text);
                    } catch (error) {
                        console.error('Decryption failed, showing encrypted text')
                    }

                    const prevMessage = messages[index - 1];
                    const showDate = !prevMessage || (prevMessage?.timestamp?.seconds && formatDate(prevMessage.timestamp) !== formatDate(msg.timestamp));

                    return (
                         <div key={msg.id}>
                            {showDate && (
                                <Typography variant="caption" display="block" align="center" sx={{mt: 2, mb: 2}}>
                                    {formatDate(msg.timestamp)}
                                </Typography>
                            )}
                    <ListItem key={index} sx={{ display: 'flex', flexDirection: msg.sender === currentUser ? 'row-reverse' : 'row', mb: 2}}>
                        <Avatar src={msg.sender === user1 ? user1Avatar: user2Avatar} />
                        <Card sx={{
                            ml: msg.sender === currentUser ? 0 : 2,
                            mr: msg.sender === currentUser ? 2 : 0,
                            backgroundColor: msg.sender === currentUser ? '#FFFDD0' : '#9BBA8E',
                        }}>
                            <CardContent>
                                <Typography variant="body2">{decryptedText}</Typography>
                                <Typography variant="caption" display="block">
                                    {msg.timestamp?.seconds ? new Date(msg.timestamp.seconds * 1000).toLocaleTimeString() : '...'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </ListItem>
                         </div>
                    );
                })}
            <div ref={messagesEndRef} />
            </List>

            {/* Input Box */}
            <Box sx={{ display: 'flex', alignItems: 'center', position: 'fixed',
            bottom: '56px', width: '90%', p: 2, backgroundColor: 'white'}}>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Rizz them up..."
                    sx={{ borderRadius: '30%' }}
                    />
                <IconButton sx={{ backgroundColor: 'brown', ml: 2 }} onClick={handleSendMessage}>
                    <SendIcon />
                </IconButton>
            </Box>
        </Box>
    );
}

export default Chat;