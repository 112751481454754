import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import Filter from '../components/Filter';
import ProfileCard from '../components/ProfileCard';
import SpeedDateModal from '../components/SpeedDateModal';
import NotificationModal from '../components/NotificationModal';
import { useSwipeable } from 'react-swipeable';
import Popup from '../components/Popup';
import { Snackbar } from '@mui/material'; // To help with little popups
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { getAuth } from 'firebase/auth';
import {getFirestore, collection, query, where, getDocs, addDoc, doc, getDoc, updateDoc} from "firebase/firestore";
import { db } from '../firebase'; // Import firestore instance

function Link() {
    const [filters, setFilters] = useState({ city: '', region: '', ageRange: ''});
    const [profiles, setProfiles] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [notificationModalOpen, setNotificationModalOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    // State variables to manage the snackbar visibility and message
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const auth = getAuth();
    const currentUser = auth.currentUser;

    // Algorithm to calculate compatibility score to recommend best profiles to user
    const calculateCompatibility = (userProfile, otherProfile) => {
        let score = 0;

        // Compare interests
        const commonInterests = userProfile.commonInterests.filter(interest =>
            // Check if the other users common interests contains users interests
            otherProfile.commonInterests.includes(interest)
        );
        score += commonInterests.length;

        // Matching criteria by adding points
        if (userProfile.city === otherProfile.city) score += 2;
        if (userProfile.region === otherProfile.region) score += 2;
        if (userProfile.personalityType === otherProfile.personalityType) score += 1;
        if (userProfile.isPartyGoer === otherProfile.isPartyGoer) score += 1;
        if (userProfile.likesPets === otherProfile.likesPets) score += 1;

        // Return the total score
        return score;
    }

    // To handle swipe for profileCards
    const handleSwipe = (direction) => {
        // Increase currentIndex to move to the next profile
        setCurrentIndex((prevIndex) => {
            const newIndex = (prevIndex + 1) % profiles.length;
            return newIndex;
        });
    };

    //Lambda function for when a user likes a profilecard
    const handleHeart = (profile) => {
        console.log(`${profile.username} has been hearted!`);
        // Show popup
        setPopupMessage('❤️ Liked');
        setPopupOpen(true);
        // Add functionality to save to liked list and notify the user
    };

    //Lambda function for when a user maybe's a profilecard
    const handleMaybe = (profile) => {
         console.log(`${profile.username} is a maybe!`);
         // Show popup
         setPopupMessage('🤔 Maybe');
         setPopupOpen(true);
        // Add functionality for the maybe action
    }

    //Lambda function for when a user wants to chat with a profilecard
    const handleRizzUp = async (profile) => {
        if (!currentUser) {
            console.log('User is not authenticated');
            return;
        }
        console.log(`Rizz up ${profile.username}`);

        // Fetch the current user's profile to get the username
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        const userData = userSnap.data();

        if (!userData || !userData.profile || !userData.profile.username) {
            console.log('Current user profile data is missing');
            return;
        }

        // Check if user has any remaining rizzUpRequests before sending
        if (userData.profile.remainingRizzUpRequests > 0) {
            // Add functionality to send a chat request or connect
            try {

                const senderUsername = userData.profile.username;

                // Check if a request already exists before sending one
                const requestRef = collection(db, 'requests');
                const q = query(requestRef, where('sender', '==', currentUser.uid), where('receiver', '==', profile.uid));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    console.log('Request already sent to this user');
                    return;
                }


                await addDoc(requestRef, {
                    sender: currentUser.uid, // Sender UID
                    senderUsername, // Sender's username
                    // Assuming profile object contains user's UID
                    receiver: profile.uid,
                    status: 'pending',
                    timestamp: new Date()
                });

                // Deduct one RizzUp request
                await updateDoc(userRef, {
                    'profile.remainingRizzUpRequests': userData.profile.remainingRizzUpRequests - 1
                });

                // Show notification modal
                setNotificationMessage(`RizzUp request sent to ${profile.username}`);
                setNotificationModalOpen(true);

                // Show popup message
                setPopupMessage(`🏹 RizzUp Request Sent to ${profile.username}!`);
                setPopupOpen(true);

                // Remove the user from the profiles list if rizzup request already sent
                setProfiles(prevProfiles => prevProfiles.filter(p => p.uid !== profile.uid));

                console.log(`Rizz up request sent to ${profile.username}`);
            } catch (error) {
                console.error('Error sending Rizz up request', error);
            }
        } else {
            // Show error snackbar if the user has run out of RizzUp requests
            setPopupMessage('You have run out RizzUp requests for today.');
            setPopupOpen(true);
        }
    };

    // Function to handle speed date request
    const handleSpeedDate = (profile) => {
        setSelectedProfile(profile);
        setModalOpen(true);
    };

    // Function to submit speed date proposal
    const handleSubmitSpeedDate = async (proposal) => {
        console.log(`Proposed speed date with ${selectedProfile.name} on ${proposal.date} at ${proposal.time}`);
        // Add functionality to send the proposal and handle acceptance
        if (!currentUser) {
            console.log('Link: User is not authenticated');
            return;
        }
         console.log(`Proposed speed date with ${selectedProfile.name} on ${proposal.date} at ${proposal.time}`);
        // Add functionality to send the proposal and handle acceptance
        // Fetch the current user's profile to get the username
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        const userData = userSnap.data();

        if(!userData || !userData.profile || !userData.profile.username) {
            console.log('Current user profile data is missing');
            return;
        }

        // Check if user has speedDate requests first
        if (userData.profile.remainingSpeedDateRequests > 0) {
            try {

                const senderUsername = userData.profile.username;

                // Check if a speed date request already exists before sending one
                const speedDateRequestRef = collection(db, 'speedDateRequests');
                const q = query(speedDateRequestRef, where('sender', '==', currentUser.uid),
                    where('receiver', '==', selectedProfile.uid));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    console.log('Link: Speed date request already sent to this user');
                    return;
                }

                await addDoc(speedDateRequestRef, {
                    completed: false,
                    sender: currentUser.uid,
                    senderUsername,
                    receiver: selectedProfile.uid,
                    status: 'pending',
                    date: proposal.date,
                    time: proposal.time,
                    timestamp: new Date()
                });

                // Deduct one SpeedDate request
                await updateDoc(userRef, {
                    'profile.remainingSpeedDateRequests': userData.profile.remainingSpeedDateRequests - 1
                });

                //setNotificationMessage(`SpeedDate request sent to ${selectedProfile.username}`);
                //setNotificationModalOpen(true);

                setPopupMessage('⏰ SpeedDate Request Sent!');
                setPopupOpen(true);
                console.log(`Link: Speed date request sent to ${selectedProfile.username}`);
            } catch (error) {
                console.error('Link: Error sending speed date request', error);
            }
        } else {
            // Show error snackbar if the user has run out of SpeedDate requests
            setPopupMessage('You have run out of SpeedDate requests for this week.');
            setPopupOpen(true);
        }
    };

   /* const swipeHandlers = useSwipeable({
        onSwipedLeft: () => handleSwipe('Left'),
        onSwipedRight: () => handleSwipe('Right'),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    }); */

    // Function to handle when filter button(which vibe?) is clicked, modal open
    const handleFilter = () => {
        setOpenFilterModal(true);
    };

    // Fetch profiles from Firestore based on filters
    useEffect(() => {
        // Background task to check if rizzUp requests number reset is due
        const checkAndResetLimits = async () => {
            console.log("Link: In CheckAndResetLimits");
            const userRef = doc(db, 'users', currentUser.uid);
            const userSnap = await getDoc(userRef);
            const userData = userSnap.data();

            // Check if userData
            if (userData) {
                // Get current date to compare later
                const now = new Date();
                // Check when the last rizzUpReset was
                const lastRizzUpReset = userData.profile.lastRizzUpReset ? userData.profile.lastRizzUpReset.toDate() : null;
                const lastSpeedDateReset = userData.profile.lastSpeedDateReset ? userData.profile.lastSpeedDateReset.toDate() : null;

                // Check if the daily reset for rizzUp requests is due
                if (lastRizzUpReset && (now - lastRizzUpReset) >= 24 * 60 * 60 * 1000) { // 24 Hours
                    await updateDoc(userRef, {
                        'profile.remainingRizzUpRequests': 2,
                        'profile.lastRizzUpReset': now
                    });
                }

                // Check if the weekly reset for SpeedDate requests is due
                if (lastSpeedDateReset && (now - lastSpeedDateReset) >= 7 * 24 * 60 * 60 * 1000) { // 1 Week
                    // Update the fields in database
                    await updateDoc(userRef, {
                        'profile.remainingSpeedRequests': 2,
                        'profile.lastSpeedDateReset': now
                    });
                }

            }
        };

        if (currentUser) {
            checkAndResetLimits();
        }

        // Fetch profiles based on filters (dummyProfiles for now)
        // setProfiles(filteredProfiles);

        const fetchProfiles = async () => {
            try {
                localStorage.clear();
                sessionStorage.clear();
                let q = collection(db, 'users');

                // Apply sexual preference filter to make sure users get what they are looking for
                const userRef = doc(db, 'users', currentUser.uid);
                const userSnap = await getDoc(userRef);
                const userProfile = userSnap.data().profile;

                console.log("Link: UserProfile.sexualPreference = ", userProfile.sexualPreference);

                // Change to Heterosexual later, remember
                if (userProfile.sexualPreference === 'Straight') {
                    if (userProfile.gender === 'Male') {
                        console.log("Link: Gender Male whose Heterosexual");
                        q = query(q, where ('profile.gender', '==', 'Female'), where('profile.sexualPreference', 'in', ['Straight', 'Bisexual']));
                    } else if (userProfile.gender === 'Female') {
                        console.log("Link: Gender Female whose Heterosexual");
                        q = query(q, where ('profile.gender', '==', 'Male'), where('profile.sexualPreference', 'in', ['Straight', 'Bisexual']));
                    }
                } else if (userProfile.sexualPreference === 'Gay') {
                    q = query(q, where('profile.gender', '==', userProfile.gender), where('profile.sexualPreference', 'in', ['Gay', 'Bisexual']));
                } else if (userProfile.sexualPreference === 'Bisexual') {
                    q = query(q, where('profile.sexualPreference', 'in', ['Bisexual', 'Straight', 'Gay']));
                }

                // Apply additional filters (city, region, age range
                if (filters.onlyMaybes) {
                    // Fetch only profiles that the current user marked as "Maybe"
                    const maybeRef = collection(db, 'maybes');
                    const maybeQuery = query(maybeRef, where('sender', '==', currentUser.uid));
                    const maybeSnapshot = await getDocs(maybeQuery);
                    const maybeUids = maybeSnapshot.docs.map(doc => doc.data().receiver);

                    console.log("Link: Maybes length = ", maybeUids.length);

                    // If there are "Maybe" profiles, filter by their UIDs
                    if (maybeUids.length > 0) {
                        // Firestore 'in' query can only take up to 10 elements, so we handle it accordingly
                        const chunks = [];
                        for (let i = 0; i < maybeUids.length; i += 1){
                            chunks.push(maybeUids.slice(i, i + 10));
                        }

                        let maybeQueries = chunks.map(chunk => query(q, where('__name__', 'in', chunk)));
                        let maybeProfiles = [];
                        for (let maybeQ of maybeQueries) {
                            const snapshot = await getDocs(maybeQ);
                            snapshot.forEach(docSnapshot => {
                                maybeProfiles.push({
                                    ...docSnapshot.data().profile,
                                    imageUrl: docSnapshot.data().profilePicture,
                                    uid: docSnapshot.id
                                });
                            });
                        }
                        // Shuffle the profiles to randomize the order for the user
                        maybeProfiles = maybeProfiles.sort(() => Math.random() - 0.5);
                        setProfiles(maybeProfiles);
                        // Reset the current index whenever the profiles list changes
                        setCurrentIndex(0);
                        return;
                    } else {
                        setProfiles([]); // No 'maybes' found
                        return;
                    }
                } else {
                    // Apply other filters if "Maybes is not selected
                    if (filters.city) {
                        q = query(q, where('profile.city', '==', filters.city));
                    }
                    if (filters.region) {
                        q = query(q, where('profile.region', '==', filters.region));
                    }
                    if (filters.ageRange) {
                        if (filters.ageRange !== "46+") {
                            const [minAge, maxAge] = filters.ageRange.split('-').map(Number);
                            console.log("Link: minAge = ", minAge);
                            console.log("Link: maxAge = ", maxAge);
                            if (!isNaN(minAge)) {
                                console.log("Link: Checking the ages filter");
                                q = query(q, where('profile.age', '>=', minAge), where('profile.age', '<=', maxAge))
                                console.log("Link: Age Range Count q = ", q);
                            }
                        }

                        // If user picks 46+ in the ageRange
                        if (filters.ageRange === "46+") {
                            const [minOldAge] = filters.ageRange.split('+').map(Number);
                            console.log("Link: minOldAge = ", minOldAge);
                            const maxOldAge = 55;
                            q = query(q, where('profile.age', '>=', minOldAge), where (
                                'profile.age', '<=', maxOldAge));
                        }
                    }
                }

                const querySnapshot = await getDocs(q);
                let profileData = [];

                // To help filter and remove profiles a user has already sent rizzUp Requests to
                for (const docSnapshot of querySnapshot.docs) {
                    const data = docSnapshot.data();
                    // Get the uid of the profileCard by getting the document id
                    const uid = docSnapshot.id;
                    console.log('Link: data.uid = ', uid);

                    // Skip the current user's own profile and admin profile
                    if (uid === currentUser.uid || data.email === 'blackdateceo@gmail.com') continue;

                    // Check if a RizzUp request has already been sent
                    const requestRef = collection(db, 'requests');
                    const requestQuery = query(requestRef, where('sender', '==', currentUser.uid), where('receiver', '==', uid));
                    // To track if currentUser already received rizzUp from that person or not
                    // console.log("Link: currentUid = ", currentUser.uid);
                    // console.log("Link: other users UID = ", uid);
                    const sentRequestQuery = query(requestRef, where('sender', '==', uid), where('receiver', '==', currentUser.uid));
                    const requestSnapshot = await getDocs(requestQuery);
                    const sentRequestSnapshot = await getDocs(sentRequestQuery);
                    console.log("Link: sentRequestSnapshot is empty? = ", sentRequestSnapshot.size);
                    console.log("Link: requestRef is empty? = ", requestSnapshot.size);

                    // If no requests exists, add the profile to the list
                    if (requestSnapshot.empty && sentRequestSnapshot.empty) {
                        // Apply height filtering if strictHeightFilter is true
                        if (filters.heightPreference && !isHeightMatch(userProfile, data.profile)) {
                            console.log("Link: Height match not found");
                            continue; // Skip if height doesn't match
                        }

                        // Get the compatibility score
                        const compatibilityScore = calculateCompatibility(userProfile, data.profile);
                        console.log("Link: Compatibility score: ", compatibilityScore);
                        console.log("Link: Compatibility with: ", data.profile.username);
                        profileData.push({
                            ...data.profile,
                            imageUrl: data.profilePicture,
                            uid: uid, // Make sure to store uid for each profile
                            compatibilityScore: compatibilityScore,
                        });
                    }
                }

                if (profileData.length === 0) {
                    console.log("No profiles found for the given filters");
                }
                // Sort profiles by compatibility score in descending order
                profileData.sort((a, b) => b.compatibilityScore - a.compatibilityScore);
                setProfiles(profileData);
                // Reset the current index whenever the profiles list changes
                setCurrentIndex(0);

            } catch (error) {
                console.error("Error fetching profiles: ", error);
            }
        };

        // Function to check if the height matches the user's preference
        const isHeightMatch = (userProfile, otherProfile) => {
            const userHeight = Number(userProfile.heightFeet) * 12 + Number(userProfile.heightInches);
            const otherHeight = Number(otherProfile.heightFeet) * 12 + Number(otherProfile.heightInches);

            console.log("Link: userHeight = ", userHeight);
            console.log("Link: otherHeight = ", otherHeight);
            console.log("Link: otherHeight > userHeight = ", otherHeight > userHeight);

            switch (userProfile.heightPreference) {
                case 'Taller':
                    console.log("Link: Taller = ", otherHeight > userHeight);
                    return otherHeight > userHeight;
                case 'Shorter':
                    console.log("Link: Shorter = ", otherHeight < userHeight);
                    return otherHeight < userHeight;
                case 'Same Height +':
                    console.log("Link: Same Height + = ", otherHeight >= userHeight);
                    return otherHeight >= userHeight;
                case 'Same Height -':
                    console.log("Link: Same Height - = ", otherHeight <= userHeight);
                    return otherHeight <= userHeight;
                case 'Don\'t Care':
                    return true;
                default:
                    return true;
            }
        };

        // Check if currentUser is properly initialized first

       /* if (currentUser && currentUser.uid) {
            fetchProfiles();
        } */
        fetchProfiles();
    }, [filters, currentUser.uid]);

    return (

        <Box sx={{ p: 4}}>
            <center><Typography variant="h4" sx={{ mb:4 }}>
               Find Your Match
            </Typography></center>
            <center><Button variant="outlined" startIcon={<FilterAltOutlinedIcon />} sx={{color: 'black'}} onClick={handleFilter}>Which Vibe?</Button></center>
            <center><Box sx={{ mt: 4 }}>
                {profiles.length > 0 ? (
                    <ProfileCard profile={profiles[currentIndex]}
                                 onHeart={handleHeart}
                                 onMightVibe={handleMaybe}
                                 onRizzUp={handleRizzUp}
                                 onSpeedDate={handleSpeedDate}
                                 onSwipe={handleSwipe}
                    />
                ) : (
                    <Typography variant="body1">No profiles found</Typography>
                )}
            </Box></center>
            {selectedProfile && (
            <SpeedDateModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onSubmit={handleSubmitSpeedDate}
            profile={selectedProfile}/>
                )}
            <Filter
                filters={filters}
                setFilters={setFilters}
                open={openFilterModal}
                onClose={() => setOpenFilterModal(false)}
                onApply={() => setOpenFilterModal(false)}
                />
            <Popup
                open={popupOpen}
                onClose={() => setPopupOpen(false)}
                message={popupMessage}
                />
        </Box>
    );
   /* return (
      <div>
          <h1>Link</h1>
      </div>
    );*/
}

export default Link;