import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Card } from '@mui/material';
import { collection, query, onSnapshot, orderBy, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebase';
import{ getAuth } from 'firebase/auth';
import {useNavigate} from "react-router-dom";
function AdminDashboard() {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [feedbackList, setFeedbackList] = useState([]);
    const [userCount, setUserCount] = useState(0);
    const [rizzUpCount, setRizzUpCount] = useState(0);
    const [speedDateCount, setSpeedDateCount] = useState(0);

    // For security purposes
    const navigate = useNavigate();
    const auth = getAuth();

    useEffect(() => {
        const user = auth.currentUser;
        if (user && user.email === 'blackdateceo@gmail.com') {
            setIsAuthorized(true);
        } else {
            navigate('/');
        }
    }, [auth, navigate]);


    useEffect(() => {
        // Fetch feedback from firestore
        const feedbackRef = collection(db, 'feedback');
        const qFeedback = query(feedbackRef, orderBy('timestamp', 'desc'));
        // Real time listener
        const unsubscribeFeedback = onSnapshot(qFeedback, (querySnapshot) => {
            const feedbackData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setFeedbackList(feedbackData);
        });

        // Fetch user count
        const fetchUserCount = async () => {
            const userRef = collection(db, 'users');
            const userSnapshot = await getDocs(userRef);
            setUserCount(userSnapshot.size);
        };

        // Fetch RizzUp and SpeedDate request counts
        const fetchRequestCounts = async () => {
            const rizzUpRef = collection(db, 'requests');
            const rizzUpSnapshot = await getDocs(rizzUpRef);
            setRizzUpCount(rizzUpSnapshot.size);

            const speedDateRef = collection(db, 'speedDateRequests');
            const speedDateSnapshot = await getDocs(speedDateRef);
            setSpeedDateCount(speedDateSnapshot.size);
        };

        fetchUserCount();
        fetchRequestCounts();

        return () => {
            unsubscribeFeedback();
        };
    }, []);

     if (!isAuthorized) {
        return null; // Prevent rendering if the user is not authorized
     }

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom><center>Admin Dashboard</center></Typography>
            <Card sx={{ p: 2, mb: 4 }}>
                <Typography variant="h6">User Statistics</Typography>
                <Typography variant="body1">Total Users: {userCount}</Typography>
                <Typography variant="body1">Total RizzUp Requests: {rizzUpCount}</Typography>
                <Typography variant="body1">Total SpeedDate Requests: {speedDateCount}</Typography>
            </Card>

            <Card sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>Feedback</Typography>
                <List>
                    {feedbackList.map((feedback) => (
                        <ListItem key={feedback.id}>
                            <ListItemText
                                primary={feedback.message}
                                secondary={`From: ${feedback.username} (${feedback.email})`}
                                />
                        </ListItem>
                    ))}
                </List>
            </Card>
        </Box>
    );
}

export default AdminDashboard;

