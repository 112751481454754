/**
 * To help hide the header and footer during video calls, using state management,
 * Context can be shared across components to manage this
 */

import React, { createContext, useState } from "react";

// Create a context to manage the video call state
export const VideoCallContext = createContext();

// Provider component to wrap around the app
export const VideoCallProvider = ({ children }) => {
    const [inVideoCall, setInVideoCall] = useState(false);

    return (
        <VideoCallContext.Provider value={{ inVideoCall, setInVideoCall }}>
            {children}
        </VideoCallContext.Provider>
    );
};