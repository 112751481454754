import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

//My Web App's Firebase Config copied from firebase
const firebaseConfig = {
  apiKey: "AIzaSyCFvRoN6cuxcMzti95FOAG2FGiqQoCGtKw",
  authDomain: "blackdate-a0ade.firebaseapp.com",
  projectId: "blackdate-a0ade",
  storageBucket: "blackdate-a0ade.appspot.com",
  messagingSenderId: "391969683964",
  appId: "1:391969683964:web:ee354fb427538c854c5a79",
  measurementId: "G-M27VVEJBKR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export { auth, db };
