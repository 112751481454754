import React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonIcon from '@mui/icons-material/Person';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import ShutterSpeedOutlinedIcon from '@mui/icons-material/ShutterSpeedOutlined';
import { doc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase';

function FooterNav() {
    const location = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = React.useState(location.pathname);
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const [isOnProfileSetup, setIsOnProfileSetup] = React.useState(false);
    const [profileComplete, setProfileComplete] = React.useState(true); // Default true to show footer
    const auth = getAuth();

    // Get the actual location and update footer to show active page icon
    React.useEffect(() => {
        const fetchProfileStatus = async (user) => {
            if (user) {
                const userRef = doc(db, 'users', user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    const userData = userSnap.data();
                    setProfileComplete(userData.profile?.profileComplete ?? false);
                }
            }
        };

        onAuthStateChanged(auth, (user) => {
            setIsLoggedIn(!!user);
            if (user) {
                fetchProfileStatus(user);
            }
        });

        // Update the current page location
        setValue(location.pathname);

        // Check if the current page is the profile setup page
        setIsOnProfileSetup(location.pathname === '/profile-setup');

    }, [location, auth]);

    const handleChange = (event, newValue) => {
            setValue(newValue);
            navigate(newValue);
    };

    // Do not show footer is user is not logged in
    console.log("Footer: Is Logged In = ", isLoggedIn);

    if (!isLoggedIn || !profileComplete) return null;

    return (
        <BottomNavigation
            value={value}
            onChange={handleChange}
            showLabels
            sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                backgroundColor: '#865D36',
                color: '#FFFFF0'
            }}
            >
            <BottomNavigationAction label="Home" value="/" icon={<HomeIcon sx={{ color: value === '/' ? '#DC143C' : '#FFD700' }} />}
                sx={{ '& .MuiBottomNavigationAction-label': { color: "#FFFFF0"} }}
                                    component={ Link } to="/" disabled={isOnProfileSetup}/>
            <BottomNavigationAction label="Link" value="/link" icon={<SearchIcon sx={{ color: value === '/link' ? '#DC143C' : '#FFD700' }} />}
                sx={{ '& .MuiBottomNavigationAction-label': { color: "#FFFFF0"} }}
                                    component={ Link } to="/link" disabled={isOnProfileSetup} />
            <BottomNavigationAction label="Matches" value="/matches" icon={<FavoriteIcon sx={{ color: value === '/matches' ? '#DC143C' : '#FFD700' }} />}
                sx={{ '& .MuiBottomNavigationAction-label': { color: "#FFFFF0"} }}
                                    component={ Link } to="/matches" disabled={isOnProfileSetup} />
            <BottomNavigationAction label="Speed" value="/speed" icon={<ShutterSpeedOutlinedIcon sx={{ color: value === '/speed' ? '#DC143C' : '#FFD700' }} />}
                sx={{ '& .MuiBottomNavigationAction-label': { color: "#FFFFF0"} }}
                                    component={ Link } to="/speed" disabled={isOnProfileSetup} />
            <BottomNavigationAction label="Profile" value="/profile" icon={<PersonIcon sx={{ color: value === '/profile' ? '#DC143C' : '#FFD700' }} />}
                sx={{ '& .MuiBottomNavigationAction-label': { color: "#FFFFF0"} }}
                                    component={ Link } to="/profile" disabled={isOnProfileSetup} />
        </BottomNavigation>
    );
}

export default FooterNav;